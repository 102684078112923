import React, { useState } from 'react'

import {
  AppActiveGoalsWrapper,
  AppFilterButtonWrapper,
  AppActiveGoalsGrid,
  AppNoGoalsMessage,
  AppFilterHeader,
  AppFiterHeadingWrapper,
} from 'components/AppGoalCenter/AppGoalCenter.style'
import { AppGoalCard } from 'components/AppGoalCenter/AppGoalCard/AppGoalCard.component'
import { getRoleFromPath } from 'utils/helper'
import history from 'utils/history'
import { ROLES } from 'config'
import {
  BusinessGoal,
  useGetUserTasksQuery,
  UserTask,
  UserTaskItemStatus,
  UserTaskQuickFilter,
} from '__generated__/api-types-and-hooks'
import {
  BusinessGoalsFilterData,
  OnboardingFlowType,
  useCreateFlowChatSessionMutation,
} from '__generated__/api-types-and-hooks'

import AppFilterPopup from 'components/AppGoalCenter/AppFilterPopup/AppFilterPopup.component'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { AppPlusIcon } from 'components/Common/AppSvgIcons/AppPlusIcon'
import redirectTo from 'utils/redirectTo'
import { ChatBotFlowUrl } from 'components/AppOnboarding/AppChatbot/AppChatBotFlows/constants'
import { getTenantId } from 'utils/getTenantId'
import { AppFilterIcon } from 'components/Common/AppSvgIcons/AppFilterIcon'
import { allTasksTodo, calculateProgress, getLatestEndDate } from 'appUtils'
import { isUndefined } from 'lodash'
import { pageRoute } from 'V2/pages/config'
import { OTHERTASKS } from 'appConfig/enums'

interface IGoalProps {
  goals: BusinessGoal[]
  selectedFilters: BusinessGoalsFilterData
  setSelectedFilters: React.Dispatch<React.SetStateAction<BusinessGoalsFilterData>>
  refetchGoals?: ({ hasFilter }: { hasFilter?: boolean }) => void
  viewArchive: boolean
  setViewArchive: React.Dispatch<React.SetStateAction<boolean>>
  allPlays: BusinessGoal[]
}

export const AppGoals: React.FC<IGoalProps> = ({
  goals,
  selectedFilters,
  setSelectedFilters,
  refetchGoals,
  viewArchive,
  setViewArchive,
  allPlays,
}) => {
  const tenantId = getTenantId()
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const role = getRoleFromPath()
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false)
  const { mutate: createFlowChatSessionMutate } = useCreateFlowChatSessionMutation()

  const { data: otherTasks } = useGetUserTasksQuery({
    input: { tenantId, filter: { quickFilter: UserTaskQuickFilter.NoProjectAssigned } },
  })

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const goalsPerPage = 6

  // Calculate the index of the last goal on the current page
  const indexOfLastGoal = currentPage * goalsPerPage
  const indexOfFirstGoal = indexOfLastGoal - goalsPerPage
  const currentGoals = goals?.slice(indexOfFirstGoal, indexOfLastGoal)

  // Calculate total pages
  const totalPages = Math.ceil(goals?.length / goalsPerPage)

  // Handler for toggling the archive view
  const handleToggleArchiveView = () => {
    setSelectedFilters((prev) => ({
      ...prev,
      isArchive: !viewArchive,
      statuses: [],
    }))
    setViewArchive(!viewArchive)
  }
  const goalsCount = viewArchive
    ? goals.filter(
        (goal) => goal.name !== OTHERTASKS.MY_OTHER_TASKS && goal.name !== OTHERTASKS.MY_ALL_PLAYS
      ).length
    : goals.length

  const { data: allTasks } = useGetUserTasksQuery({
    input: { tenantId, filter: { quickFilter: UserTaskQuickFilter.AllGoalsTasks } },
  })
  return (
    <AppActiveGoalsWrapper>
      <AppFilterHeader>
        <AppFiterHeadingWrapper>
          <h3 className="text-3xl font-medium font-inter leading-9 ">
            {viewArchive ? 'Archived Goals' : 'Active Goals'}
          </h3>
          <p className="text-sm font-inter font-normal leading-6 text-app-grey-75">
            {viewArchive
              ? 'Below you will find any archived or completed goals'
              : ' Below you will find your goals. Click on each to work towards it.'}
          </p>
        </AppFiterHeadingWrapper>

        <AppFilterButtonWrapper>
          <AppButton
            variant="primary"
            size="lg"
            label="Add Goal"
            RightIcon={AppPlusIcon}
            onClick={() => {
              createFlowChatSessionMutate(
                {
                  input: {
                    flow: OnboardingFlowType.AddGoal,
                  },
                },
                {
                  onSuccess: (res) => {
                    const tenantId = getTenantId()
                    redirectTo(
                      `/owner-v2/${tenantId}/onboarding/${
                        ChatBotFlowUrl[OnboardingFlowType.AddGoal]
                      }/${res.createFlowChatSession.id}`
                    )
                  },
                }
              )
            }}
          />
          <div className="relative overflow-visible">
            <AppButton
              variant="secondary"
              onClick={() => setIsFilterPopupOpen(true)}
              label="Filter"
              size="lg"
              LeftIcon={AppFilterIcon}
            />
            {isFilterPopupOpen && (
              <AppFilterPopup
                isOpen={isFilterPopupOpen}
                onClose={() => setIsFilterPopupOpen(false)}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                disabled={viewArchive}
              />
            )}
          </div>
          <AppButton
            variant="secondary"
            size="lg"
            label={viewArchive ? 'View Active Goals' : 'View Archive'}
            onClick={handleToggleArchiveView}
          />
        </AppFilterButtonWrapper>
      </AppFilterHeader>

      <AppActiveGoalsGrid>
        {!isUndefined(otherTasks) && allPlays && currentGoals?.length > 0
          ? currentGoals
              .filter(
                (goal) =>
                  !(
                    viewArchive &&
                    (goal.name === OTHERTASKS.MY_OTHER_TASKS ||
                      goal.name === OTHERTASKS.MY_ALL_PLAYS)
                  )
              )
              .map((goal) => {
                const isMyOtherTask = goal.name === OTHERTASKS.MY_OTHER_TASKS
                const isAllTasks = goal.name === OTHERTASKS.MY_ALL_PLAYS
                const progress = isMyOtherTask
                  ? calculateProgress(otherTasks?.getUserTasks!)
                  : isAllTasks
                  ? calculateProgress(allTasks?.getUserTasks!)
                  : calculateProgress(goal.plan?.tasks as UserTask[])
                const expectedEndDate = isMyOtherTask
                  ? getLatestEndDate(otherTasks?.getUserTasks)
                  : isAllTasks
                  ? getLatestEndDate(allPlays)
                  : goal.expectedEndDate!

                const handleClick = () => {
                  if (viewArchive || goal.status === UserTaskItemStatus.Creating) return
                  const basePath = `/${role}/${tenantId}`
                  const getPath = () => {
                    return role?.includes(ROLES.BUSINESS_OWNER)
                      ? `${basePath}/goals/${goal.goalId}/${pageRoute.gameMode}`
                      : `${basePath}/clients/${clientId}/goals/${goal.goalId}/${pageRoute.gameMode}`
                  }
                  history.push(getPath())
                }

                return (
                  <AppGoalCard
                    key={goal.goalId}
                    progress={progress}
                    goalId={goal.goalId!}
                    onClick={handleClick}
                    logo={goal.logo ?? ''}
                    goalStatus={goal.status}
                    category={goal.category!}
                    refetchGoals={refetchGoals}
                    isMyOtherTask={isMyOtherTask || isAllTasks}
                    description={isMyOtherTask || isAllTasks ? goal?.description! : ''}
                    priority={goal.goalPriority!}
                    expectedEndDate={expectedEndDate}
                    allTaskTodo={allTasksTodo(goal.plan)}
                    title={goal.name || 'No goal name provided'}
                  />
                )
              })
          : null}
      </AppActiveGoalsGrid>

      {goalsCount === 0 &&
        (viewArchive ? (
          <AppNoGoalsMessage>No Archived Goals</AppNoGoalsMessage>
        ) : (
          <AppNoGoalsMessage>No Active Goals</AppNoGoalsMessage>
        ))}
      <div className="flex justify-center w-full">
        <div className="flex gap-2">
          <AppButton
            variant="clear"
            size="xs"
            label="Previous"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <AppButton
              key={index}
              variant="clear"
              size="xs"
              label={`${index + 1}`}
              onClick={() => setCurrentPage(index + 1)}
            />
          ))}
          <AppButton
            variant="clear"
            size="xs"
            label="Next"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          />
        </div>
      </div>
    </AppActiveGoalsWrapper>
  )
}
